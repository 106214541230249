import MusicHomePage from './pages/MusicHome';
import MusicBio from './pages/MusicBio';
import MusicVideos from './pages/MusicVideos';
import { BrowserRouter, Routes, Route } from "react-router-dom";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MusicHomePage />}></Route>
        <Route path="/bio" element={<MusicBio />}></Route>
        <Route path="/videos" element={<MusicVideos />}></Route>
      </Routes>
    </BrowserRouter>
  );
}


