import "../index.css";
import img from "../img/latest-single.jpg";
import CustomForm from "../components/NewsletterSubscribe";
import {
  FaSpotify,
  FaMoon,
  FaSun,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import { useState } from "react";

function MusicHomePage() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  let theme = "";
  let hidden = "hidden ";

  if (isDarkMode) {
    theme = "theme-dark ";
  }

  // If not hidden, make hidden on click
  if (!isHidden) {
    hidden = "";
  }

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className={theme}>
      {/* Navbar */}
      <nav className="bg-skin-fill relative w-full translate-y-1 justify-around space-x-2 pt-5 text-center md:items-center lg:justify-around lg:py-2">
        {/* Signature, links back to home page */}
        <div className="flex items-center justify-evenly p-8 lg:justify-around xl:left-10">
          <a href="/" className="text-center uppercase">
            <span className="heading text-skin-base hover:text-skin-accent text-3xl sm:text-4xl ">
              Hayley Sabella
            </span>
          </a>

          {/* Toggle theme switch */}
          <div
            className="absolute left-3 top-3 lg:static"
            onChange={toggleTheme}
          >
            <input type="checkbox" className="checkbox" id="checkbox" />
            <label for="checkbox" className="label">
              <FaSun className="text-skin-inverted mr-2 text-[12px]"></FaSun>
              <FaMoon className="text-skin-inverted text-[10px]"></FaMoon>
              <div className="ball"></div>
            </label>
          </div>

          {/* Secondary nav */}
          <div
            className={`text-skin-base hidden items-center justify-center space-x-3 text-center text-lg lg:flex`}
          >
            <a href="/" className="hover:text-skin-accent py-4   px-3">
              Home
            </a>
            <a href="/bio" className="hover:text-skin-accent py-4   px-3">
              Bio
            </a>
            <a
              href="https://www.bandsintown.com/a/4875862-hayley-sabella"
              target="blank"
              className="hover:text-skin-accent py-4   px-3"
            >
              Tour
            </a>
            <a href="/videos" className="hover:text-skin-accent py-4   px-3">
              Videos
            </a>
            <a
              href="https://hayleysabella.bandcamp.com/merch"
              target="blank"
              className="hover:text-skin-accent py-4   px-3"
            >
              Store
            </a>
          </div>
        </div>

        {/* Mobile nav button */}
        <div className="text-skin-base absolute top-2 right-3 lg:hidden">
          <GiHamburgerMenu
            className="text-2xl"
            onClick={toggleHidden}
          ></GiHamburgerMenu>
        </div>

        {/* Mobile Nav */}
        <div
          className={`${hidden} mobile-menu bg-skin-fill pb-10`}
          id="mobile-nav"
        >
          <div className="mt-4 flex flex-col justify-start space-y-7 text-center  text-xl">
            <a
              href="/"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Home
            </a>
            <a
              href="/bio"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Bio
            </a>
            <a
              href="https://www.bandsintown.com/a/4875862-hayley-sabella"
              target="blank"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Tour
            </a>
            <a
              href="/videos"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Videos
            </a>
            <a
              href="https://hayleysabella.bandcamp.com/merch"
              target="blank"
              className="text-skin-base  hover:text-skin-accent pb-2 hover:text-blue-500"
            >
              Store
            </a>
          </div>
        </div>
      </nav>

      {/* Hero */}
      <section
        class={` bg-skin-fill flex w-screen flex-wrap justify-evenly  space-y-10 md:flex-nowrap md:space-y-0 md:p-10`}
      >
        <a
          href="https://ffm.to/alivebutimtired.ois"
          class="w-full rounded bg-contain hover:opacity-90"
        >
          <img src={img} alt="" class="rounded object-cover shadow-lg" />
        </a>

        <div class="flex w-screen flex-col items-center justify-center md:p-10">
          <div class="dosis text-skin-base space-y-5 text-center text-4xl font-light uppercase md:text-5xl lg:space-y-7 xl:text-6xl 2xl:text-7xl">
            <p class="leading-normal">Listen to the latest single</p>
          </div>
          <a href="https://ffm.to/alivebutimtired.ois" target="blank">
            <div class="hero-btn bg-skin-primary text-skin-muteverted hover:text-skin-accent my-6 mb-10 cursor-pointer p-3 text-xl font-extralight uppercase shadow-md lg:my-10 lg:p-5 lg:text-2xl">
              click here!
            </div>
          </a>
        </div>
      </section>

      {/* Listen to Hayley */}
      <section class="parrilex bg-skin-image flex flex-col items-center justify-center space-y-10 bg-cover bg-fixed bg-center p-8 text-center bg-blend-overlay md:p-10 lg:p-36 ">
        <div class="flex flex-col items-center justify-center space-y-10 p-8 text-center md:p-10 lg:p-36">
          <div class="dosis white text-4xl font-light uppercase md:text-5xl xl:text-6xl 2xl:text-7xl ">
            Listen to Hayley
          </div>
          <a
            href="https://youtu.be/37-b1k07QgQ"
            target="blank"
            class="btn2 lg:min-w-max"
          >
            Flew the Nest Music Video
          </a>
          <a
            href="https://youtu.be/0vzQ2jlaboU"
            target="blank"
            class="btn2 lg:min-w-max"
          >
            tiny desk submission
          </a>
          <a
            href="https://youtu.be/aMn7ZulaMBM"
            target="blank"
            class="btn2 lg:min-w-max"
          >
            watch 'love is a chisel' live
          </a>
        </div>
      </section>

      {/* Newsletter Signup */}
      <section className="bg-skin-fill flex h-96 flex-col items-center justify-center">
        <div class="text-skin-base dosis text-4xl font-light uppercase md:text-5xl xl:text-6xl 2xl:text-7xl">
          <p class="bg-text-base text-center">Newsletter Signup</p>
        </div>
        <CustomForm className="mt-10"></CustomForm>
      </section>

      {/* See Live */}
      <div class="see-live flex flex-col items-center justify-center space-y-10  bg-slate-700 bg-cover p-24  text-center bg-blend-overlay lg:p-36">
        <div class="text-skin-base dosis text-4xl font-light uppercase md:text-5xl xl:text-6xl 2xl:text-7xl">
          <p class="white w-80 leading-snug">see hayley live</p>
        </div>
        <a
          href="https://www.bandsintown.com/a/4875862-hayley-sabella"
          target="_blank"
        >
          <div class="btn2 uppercase">see tour dates</div>
        </a>
      </div>

      {/* Footer */}

      <footer className="bg-footer flex flex-col justify-evenly p-10 text-center lg:flex-row">
        {/* Col 1 */}
        <div className="white flex flex-col space-y-2 text-center text-lg lg:space-y-3">
          <h3 className="footer-heading text-lg sm:mb-4 sm:text-2xl lg:mb-1">
            some important stuff
          </h3>
          <a
            href="/bio"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
          >
            Bio
          </a>
          <a
            href="https://hayleysabella.bandcamp.com/merch"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
          >
            Store
          </a>
          <a
            href="/videos"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
          >
            Videos
          </a>
        </div>

        {/* Col 2 */}
        <div class="flex h-full flex-col justify-center text-center">
          <h3 class="footer-heading text-light-gray mt-8 text-lg sm:mb-4 sm:text-2xl lg:mt-0 lg:mb-7">
            connect
          </h3>
          <div class="white my-5 flex items-center justify-center space-x-10 text-4xl sm:my-0">
            <a
              class="link"
              href="https://open.spotify.com/album/3EwHuGIfYNm1Whnulfd0mu"
            >
              <FaSpotify class="fa-brands text-gray-1 hover:text-skin-accent "></FaSpotify>
            </a>
            <a
              class="link"
              href="https://www.youtube.com/channel/UCJLNPZa-kX0nsWvKGHcYsCw"
            >
              <FaYoutube class="fa-brands text-gray-1 fa-youtube hover:text-skin-accent"></FaYoutube>
            </a>
            <a class="link" href="https://www.instagram.com/haysab/">
              <FaInstagram class="fa-brands text-gray-1 fa-instagram hover:text-skin-accent"></FaInstagram>
            </a>
          </div>
        </div>

        {/* Col 3 */}
        <div class="mt-10 flex h-full flex-col justify-center text-center lg:mt-0">
          <h3 class="footer-heading text-light-gray text-lg sm:text-2xl">
            Newsletter
          </h3>
          <CustomForm></CustomForm>
        </div>
      </footer>
    </div>
  );
}
export default MusicHomePage;
