import "../index.css";
import YoutubeEmbed from "../components/YoutubeEmbed";
import CustomForm from "../components/NewsletterSubscribe";
import {
  FaSpotify,
  FaMoon,
  FaSun,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import { useState } from "react";

function MusicVideos() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  let theme = "";
  let hidden = "hidden ";

  if (isDarkMode) {
    theme = "theme-dark ";
  }

  // If not hidden, make hidden on click
  if (!isHidden) {
    hidden = "";
  }

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  return (
    <div className={theme}>
      {/* Navbar */}
      <nav className="bg-skin-fill relative w-full translate-y-1 justify-around space-x-2 pt-5 text-center md:items-center lg:justify-around lg:py-2">
        {/* Signature, links back to home page */}
        <div className="flex items-center justify-evenly p-8 lg:justify-around xl:left-10">
          <a href="/" className="text-center uppercase">
            <span className="heading text-skin-base hover:text-skin-accent text-3xl sm:text-4xl ">
              Hayley Sabella
            </span>
          </a>

          {/* Toggle theme switch */}
          <div
            className="absolute left-3 top-3 lg:static"
            onChange={toggleTheme}
          >
            <input type="checkbox" className="checkbox" id="checkbox" />
            <label for="checkbox" className="label">
              <FaSun className="text-skin-inverted mr-2 text-[12px]"></FaSun>
              <FaMoon className="text-skin-inverted text-[10px]"></FaMoon>
              <div className="ball"></div>
            </label>
          </div>

          {/* Secondary nav */}
          <div
            className={`text-skin-base hidden items-center justify-center space-x-3 text-center text-lg lg:flex`}
          >
            <a href="/" className="hover:text-skin-accent py-4   px-3">
              Home
            </a>
            <a href="/bio" className="hover:text-skin-accent py-4   px-3">
              Bio
            </a>
            <a
              href="https://www.bandsintown.com/a/4875862-hayley-sabella"
              target="blank"
              className="hover:text-skin-accent py-4   px-3"
            >
              Tour
            </a>
            <a href="/videos" className="hover:text-skin-accent py-4   px-3">
              Videos
            </a>
            <a
              href="https://hayleysabella.bandcamp.com/merch"
              target="blank"
              className="hover:text-skin-accent py-4   px-3"
            >
              Store
            </a>
          </div>
        </div>

        {/* Mobile nav button */}
        <div className="text-skin-base absolute top-2 right-3 lg:hidden">
          <GiHamburgerMenu
            className="text-2xl"
            onClick={toggleHidden}
          ></GiHamburgerMenu>
        </div>

        {/* Mobile Nav */}
        <div
          className={`${hidden} mobile-menu bg-skin-fill pb-10`}
          id="mobile-nav"
        >
          <div className="mt-4 flex flex-col justify-start space-y-7 text-center  text-xl">
            <a
              href="/"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Home
            </a>
            <a
              href="/bio"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Bio
            </a>
            <a
              href="https://www.bandsintown.com/a/4875862-hayley-sabella"
              target="blank"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Tour
            </a>
            <a
              href="/videos"
              className="text-skin-base  hover:text-skin-accent hover:text-blue-500"
            >
              Videos
            </a>
            <a
              href="https://hayleysabella.bandcamp.com/merch"
              target="blank"
              className="text-skin-base  hover:text-skin-accent pb-2 hover:text-blue-500"
            >
              Store
            </a>
          </div>
        </div>
      </nav>

      {/* Videos Section */}
      <div className="bg-skin-fill">
        <div className="video mb-12 flex aspect-[12/6] items-center justify-center bg-cover bg-fixed bg-top bg-no-repeat lg:aspect-[12/3]">
          <h1 className="main-heading text-light z-20 text-5xl md:text-7xl">
            Video
          </h1>
        </div>

        <div className="grid grid-cols-1 gap-5 p-5  lg:grid-cols-2 lg:gap-12 lg:p-24">
          <YoutubeEmbed
            embedId="37-b1k07QgQ"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="0vzQ2jlaboU"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="4JZCSWFYL1A"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="66YA0m3_HVE"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="sLaacwwoZnc"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="FFvtlTuDWSA"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="aMn7ZulaMBM"
            className="p-10
              "
          ></YoutubeEmbed>
          <YoutubeEmbed
            embedId="9_XLT8de2oQ"
            className="p-10
              "
          ></YoutubeEmbed>
        </div>
      </div>

      {/* Footer */}

      <footer className="bg-footer flex flex-col justify-evenly p-10 text-center lg:flex-row">
        {/* Col 1 */}
        <div className="white flex flex-col space-y-2 text-center text-lg lg:space-y-3">
          <h3 className="footer-heading text-lg sm:mb-4 sm:text-2xl lg:mb-1">
            some important stuff
          </h3>
          <a
            hred="/bio"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
            href=""
          >
            Bio
          </a>
          <a
            hred="https://hayleysabella.bandcamp.com/merch"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
            href=""
          >
            Store
          </a>
          <a
            hred="/videos"
            className="text-gray-1 footer-text hover:text-skin-accent text-sm sm:text-lg"
            href=""
          >
            Videos
          </a>
        </div>

        {/* Col 2 */}
        <div class="flex h-full flex-col justify-center text-center">
          <h3 class="footer-heading text-light-gray mt-8 text-lg sm:mb-4 sm:text-2xl lg:mt-0 lg:mb-7">
            connect
          </h3>
          <div class="white my-5 flex items-center justify-center space-x-10 text-4xl sm:my-0">
            <a
              class="link"
              href="https://open.spotify.com/album/3EwHuGIfYNm1Whnulfd0mu"
            >
              <FaSpotify class="fa-brands text-gray-1 hover:text-skin-accent "></FaSpotify>
            </a>
            <a
              class="link"
              href="https://www.youtube.com/channel/UCJLNPZa-kX0nsWvKGHcYsCw"
            >
              <FaYoutube class="fa-brands text-gray-1 fa-youtube hover:text-skin-accent"></FaYoutube>
            </a>
            <a class="link" href="https://www.instagram.com/haysab/">
              <FaInstagram class="fa-brands text-gray-1 fa-instagram hover:text-skin-accent"></FaInstagram>
            </a>
          </div>
        </div>

        {/* Col 3 */}
        <div class="mt-10 flex h-full flex-col justify-center text-center lg:mt-0">
          <h3 class="footer-heading text-light-gray text-lg sm:text-2xl">
            Newsletter
          </h3>
          <CustomForm></CustomForm>
        </div>
      </footer>
    </div>
  );
}
export default MusicVideos;
