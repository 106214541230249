import MailchimpSubscribe from "react-mailchimp-subscribe"
import '../index.css';

const url = "https://hayleysabella.us8.list-manage.com/subscribe/post?u=3735a8fabbfa720933e43e054&amp;id=895aed81f8&amp;f_id=00b26be0f0";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

// use the render prop and your custom form
const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div className="lg:m-0 space-x-9" id="footer-newsletter">
        <SimpleForm onSubmitted={formData => subscribe(formData)}/>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }} >Subscribed !</div>}
      </div>
    )}
  />
)

export default CustomForm